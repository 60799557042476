var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('nav-bar',{attrs:{"fixedNav":"false"}}),_c('div',{staticClass:"content"},[_c('b-container',{staticClass:"registration-cont",attrs:{"fluid":""}},[_c('form',{attrs:{"id":"registration-form"}},[_c('div',{staticClass:"container text-center"},[_c('h2',{staticClass:"text-center"},[_vm._v("Register")]),_c('div',{staticClass:"mt-3 mb-1"},[_c('SocialMedia')],1),_c('p',{staticClass:"medium text-center"},[_vm._v("OR")]),(_vm.showUserTypeField)?_c('b-input-group',{attrs:{"prepend":"User Type"}},[_c('b-form-select',{staticClass:"form-control",attrs:{"id":"user_type","autocomplete":"off","state":!_vm.$v.userType.$error,"options":_vm.userTypeOptions},on:{"input":function($event){return _vm.$v.userType.$touch()}},model:{value:(_vm.userType),callback:function ($$v) {_vm.userType=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"userType"}}),_c('b-form-invalid-feedback',{attrs:{"id":"user_type"}},[(!_vm.$v.userType.required)?_c('span',[_vm._v(" User Type is required. ")]):_vm._e()])],1):_vm._e(),_c('b-input-group',{attrs:{"prepend":"Nickname"}},[_c('b-form-input',{attrs:{"id":"nickname","type":"text","placeholder":"Nickname","aria-describedby":"Nickname","autocomplete":"off","state":!_vm.$v.registration.nickname.$error},on:{"input":function($event){return _vm.$v.registration.nickname.$touch()},"focusout":function($event){return _vm.sanitizeWhitespace('nickname',
                            _vm.registration)}},model:{value:(_vm.registration.nickname),callback:function ($$v) {_vm.$set(_vm.registration, "nickname", $$v)},expression:"registration.nickname"}}),_c('b-form-invalid-feedback',{attrs:{"id":"nickname"}},[(!_vm.$v.registration.nickname.required)?_c('span',[_vm._v(" Nickname is required. ")]):_vm._e(),(!_vm.$v.registration.nickname.safeNickname)?_c('span',[_vm._v(" Nickname not allowed. ")]):_vm._e()])],1),_c('b-input-group',{attrs:{"prepend":"First Name"}},[_c('b-form-input',{attrs:{"id":"firstname","type":"text","placeholder":"First Name","aria-describedby":"FirstName","autocomplete":"off","state":!_vm.$v.registration.firstname.$error},on:{"input":function($event){return _vm.$v.registration.firstname.$touch()},"focusout":function($event){return _vm.sanitizeWhitespace('firstname',
                            _vm.registration)}},model:{value:(_vm.registration.firstname),callback:function ($$v) {_vm.$set(_vm.registration, "firstname", $$v)},expression:"registration.firstname"}}),_c('b-form-invalid-feedback',{attrs:{"id":"FirstName"}},[(!_vm.$v.registration.firstname.required)?_c('span',[_vm._v(" Firstname is required. ")]):_vm._e(),(_vm.$v.registration.firstname.required &&
                      !_vm.$v.registration.firstname.minLength)?_c('span',[_vm._v(" Minimum length must be 2 characters. ")]):_vm._e(),(!_vm.$v.registration.firstname.name)?_c('span',[_vm._v(" Contains invalid characters. ")]):_vm._e(),(!_vm.$v.registration.firstname.safeFirstName)?_c('span',[_vm._v(" First Name not allowed. ")]):_vm._e()])],1),_c('b-input-group',{attrs:{"prepend":"Last Name"}},[_c('b-form-input',{attrs:{"id":"lastname","type":"text","placeholder":"Last Name","aria-describedby":"LastName","autocomplete":"off","state":!_vm.$v.registration.lastname.$error},on:{"input":function($event){return _vm.$v.registration.lastname.$touch()},"focusout":function($event){return _vm.sanitizeWhitespace('lastname',
                            _vm.registration)}},model:{value:(_vm.registration.lastname),callback:function ($$v) {_vm.$set(_vm.registration, "lastname", $$v)},expression:"registration.lastname"}}),_c('b-form-invalid-feedback',{attrs:{"id":"LastName"}},[(!_vm.$v.registration.lastname.required)?_c('span',[_vm._v(" Lastname is required. ")]):_vm._e(),(_vm.$v.registration.lastname.required &&
                      !_vm.$v.registration.lastname.minLength)?_c('span',[_vm._v(" Minimum length must be 2 characters. ")]):_vm._e(),(_vm.$v.registration.lastname.required &&
                      _vm.$v.registration.lastname.minLength &&
                      !_vm.$v.registration.lastname.name)?_c('span',[_vm._v(" Contains invalid characters. ")]):_vm._e(),(!_vm.$v.registration.lastname.safeLastName)?_c('span',[_vm._v(" Last Name not allowed. ")]):_vm._e()])],1),_c('b-input-group',{attrs:{"prepend":"Email"}},[_c('b-form-input',{attrs:{"id":"email","type":"text","placeholder":"Email","aria-describedby":"Email","autocomplete":"off","disabled":""},model:{value:(_vm.registration.email),callback:function ($$v) {_vm.$set(_vm.registration, "email", $$v)},expression:"registration.email"}})],1),_c('b-input-group',{attrs:{"prepend":"Password"}},[_c('b-form-input',{attrs:{"id":"password","type":"password","placeholder":"Password","aria-describedby":"Password","state":!_vm.$v.registration.password.$error},on:{"input":function($event){return _vm.$v.registration.password.$touch()}},model:{value:(_vm.registration.password),callback:function ($$v) {_vm.$set(_vm.registration, "password", $$v)},expression:"registration.password"}}),_c('b-form-invalid-feedback',{attrs:{"id":"Password"}},[(!_vm.$v.registration.password.required)?_c('span',[_vm._v(" Password is required. ")]):_vm._e(),(_vm.$v.registration.password.required &&
                      !_vm.$v.registration.password.minLength)?_c('span',[_vm._v(" Minimum Length should be 5 characters. ")]):_vm._e()])],1),(_vm.userType === '1' || _vm.userType === '3' ||
            _vm.userType === '4')?_c('div',[(_vm.hasSchool)?_c('school-widget',{key:'has',attrs:{"classes":'registration-form',"prepend":true,"allowAdd":true,"initial":_vm.initial,"disabled":true},on:{"changed":_vm.updateRegSchool}}):_c('school-widget',{key:'has-not',attrs:{"classes":'registration-form',"prepend":true,"allowAdd":true},on:{"changed":_vm.updateRegSchool}}),_c('b-input-group',{directives:[{name:"show",rawName:"v-show",value:(_vm.userType === '1'),expression:"userType === '1'"}],attrs:{"prepend":"Grade Level"}},[_c('b-form-select',{staticClass:"form-control",attrs:{"id":"grade_level","autocomplete":"off","state":!_vm.$v.registration.gradeLevel.$error,"options":_vm.options},on:{"input":function($event){return _vm.$v.registration.gradeLevel.$touch()}},model:{value:(_vm.registration.gradeLevel),callback:function ($$v) {_vm.$set(_vm.registration, "gradeLevel", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"registration.gradeLevel"}}),_c('b-form-invalid-feedback',{attrs:{"id":"grade_level"}},[(!_vm.$v.registration.gradeLevel.required)?_c('span',[_vm._v(" Grade Level is required. ")]):_vm._e()])],1)],1):_vm._e(),_c('b-button',{staticClass:"btn btn-success btn-h3-small text-center mb-3",attrs:{"type":"submit","disabled":_vm.$v.registration.$invalid ||
                      _vm.response.status === 'pending' || _vm.userType === null},on:{"click":function($event){$event.preventDefault();return _vm.register.apply(null, arguments)}}},[_vm._v(" Submit ")])],1)])]),_c('loading',{attrs:{"active":_vm.isLoading,"is-full-page":true,"background-color":"#000","color":"#068bcd","opacity":0.5,"width":70,"height":70},on:{"update:active":function($event){_vm.isLoading=$event}}},[_c('rainbow-loader')],1)],1),_c('contact-button',{attrs:{"visibleFooter":"false"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }