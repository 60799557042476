<template>
  <div>
    <!-- Navigation -->
    <nav-bar fixedNav="false"></nav-bar>

    <div class="content">
      <b-container class="registration-cont" fluid>
        <form id="registration-form">
          <div class="container text-center">
            <h2 class="text-center">Register</h2>
            <div class="mt-3 mb-1">
              <SocialMedia />
            </div>
            <p class="medium text-center">OR</p>
            <b-input-group prepend="User Type" v-if="showUserTypeField">
              <b-form-select class="form-control"
                             id="user_type"
                             autocomplete="off"
                             @input="$v.userType.$touch()"
                             :state="!$v.userType.$error"
                             v-model.trim="userType"
                             :options="userTypeOptions"/>
              <b-form-invalid-feedback id="user_type">
                  <span v-if="!$v.userType.required">
                    User Type is required.
                  </span>
              </b-form-invalid-feedback>
            </b-input-group>
            <b-input-group prepend="Nickname">
              <b-form-input id="nickname"
                            type="text"
                            placeholder="Nickname"
                            aria-describedby="Nickname"
                            autocomplete="off"
                            @input="$v.registration.nickname.$touch()"
                            v-model="registration.nickname"
                            :state="!$v.registration.nickname.$error"
                            @focusout="sanitizeWhitespace('nickname',
                              registration)">
              </b-form-input>
              <b-form-invalid-feedback id="nickname">
                <span v-if="!$v.registration.nickname.required">
                  Nickname is required.
                </span>
                <span v-if="!$v.registration.nickname.safeNickname">
                  Nickname not allowed.
                </span>
              </b-form-invalid-feedback>
            </b-input-group>
            <b-input-group prepend="First Name">
              <b-form-input id="firstname"
                            type="text"
                            placeholder="First Name"
                            aria-describedby="FirstName"
                            autocomplete="off"
                            @input="$v.registration.firstname.$touch()"
                            v-model="registration.firstname"
                            :state="!$v.registration.firstname.$error"
                            @focusout="sanitizeWhitespace('firstname',
                              registration)">
              </b-form-input>
              <b-form-invalid-feedback id="FirstName">
                <span v-if="!$v.registration.firstname.required">
                  Firstname is required.
                </span>
                <span v-if="$v.registration.firstname.required &&
                        !$v.registration.firstname.minLength">
                  Minimum length must be 2 characters.
                </span>
                <span v-if="!$v.registration.firstname.name">
                  Contains invalid characters.
                </span>
                <span v-if="!$v.registration.firstname.safeFirstName">
                  First Name not allowed.
                </span>
              </b-form-invalid-feedback>
            </b-input-group>

            <b-input-group prepend="Last Name">
              <b-form-input id="lastname"
                            type="text"
                            placeholder="Last Name"
                            aria-describedby="LastName"
                            autocomplete="off"
                            @input="$v.registration.lastname.$touch()"
                            v-model="registration.lastname"
                            :state="!$v.registration.lastname.$error"
                            @focusout="sanitizeWhitespace('lastname',
                              registration)">
              </b-form-input>
              <b-form-invalid-feedback id="LastName">
                <span v-if="!$v.registration.lastname.required">
                  Lastname is required.
                </span>
                <span v-if="$v.registration.lastname.required &&
                        !$v.registration.lastname.minLength">
                  Minimum length must be 2 characters.
                </span>
                <span v-if="$v.registration.lastname.required &&
                        $v.registration.lastname.minLength &&
                        !$v.registration.lastname.name">
                  Contains invalid characters.
                </span>
                <span v-if="!$v.registration.lastname.safeLastName">
                  Last Name not allowed.
                </span>
              </b-form-invalid-feedback>
            </b-input-group>

            <b-input-group prepend="Email">
              <b-form-input id="email"
                            type="text"
                            placeholder="Email"
                            aria-describedby="Email"
                            v-model="registration.email"
                            autocomplete="off"
                            disabled>
              </b-form-input>
            </b-input-group>

            <b-input-group prepend="Password">
              <b-form-input id="password"
                            type="password"
                            placeholder="Password"
                            aria-describedby="Password"
                            @input="$v.registration.password.$touch()"
                            v-model="registration.password"
                            :state="!$v.registration.password.$error">
              </b-form-input>
              <b-form-invalid-feedback id="Password">
                <span v-if="!$v.registration.password.required">
                  Password is required.
                </span>
                <span v-if="$v.registration.password.required &&
                        !$v.registration.password.minLength">
                  Minimum Length should be 5 characters.
                </span>
              </b-form-invalid-feedback>
            </b-input-group>

            <div v-if="userType === '1' || userType === '3' ||
              userType === '4'">
              <school-widget :classes="'registration-form'"
                             :prepend="true"
                             :allowAdd="true"
                             @changed="updateRegSchool"
                             :key="'has'"
                             v-if="hasSchool"
                             :initial="initial"
                             :disabled="true"
              />
              <school-widget :classes="'registration-form'"
                             :prepend="true"
                             :allowAdd="true"
                             @changed="updateRegSchool"
                             :key="'has-not'"
                             v-else
              />
              <b-input-group prepend="Grade Level" v-show="userType === '1'">
                <b-form-select class="form-control"
                               id="grade_level"
                               autocomplete="off"
                               @input="$v.registration.gradeLevel.$touch()"
                               :state="!$v.registration.gradeLevel.$error"
                               v-model.trim="registration.gradeLevel"
                               :options="options"/>
                <b-form-invalid-feedback id="grade_level">
                  <span v-if="!$v.registration.gradeLevel.required">
                    Grade Level is required.
                  </span>
                </b-form-invalid-feedback>
              </b-input-group>
            </div>

            <b-button class="btn btn-success btn-h3-small text-center mb-3"
                      type="submit" @click.prevent="register"
                      :disabled="$v.registration.$invalid ||
                        response.status === 'pending' || userType === null">
              Submit
            </b-button>
          </div>
        </form>
      </b-container>

      <!-- Loading -->
      <loading :active.sync="isLoading"
               :is-full-page="true"
               background-color="#000"
               color="#068bcd"
               :opacity="0.5"
               :width="70"
               :height="70">
        <rainbow-loader></rainbow-loader>
      </loading>
    </div>

    <!-- Contact Form -->
    <contact-button visibleFooter="false"></contact-button>

    <!-- Modal -->
    <!-- <modal-container /> -->
  </div>
</template>

<script>
  const SocialMedia = () => import('@/components/common/SocialMediaButtons');
  const NavBar = () => import('@/components/shared/Navbar.vue');
  const ContactButton = () => import('@/components/shared/ContactButton.vue');
  const SchoolWidget = () => import('@/components/shared/SchoolWidget.vue');
  const Loading = () => import('vue-loading-overlay');
  const RainbowLoader = () => import('@/components/shared/RainbowLoader');
  
  import ProfanityMixin from '../../mixins/ProfanityMixin';
  import { required, minLength, helpers } from 'vuelidate/lib/validators';
  import _axios from 'axios';
  import 'vue-loading-overlay/dist/vue-loading.css';

  const name = helpers.regex(name, /^([A-Za-z]+[,.]?[ ]?|[A-Za-z]+['-]?)+$/);
  const instanceRegistration = _axios.create({
    baseURL : process.env.VUE_APP_ROOT_API,
    headers : {
      'Content-Type'  : 'application/json',
      'Authorization' : 'Bearer ' + localStorage.getItem('shortToken'),
    },
  });

  export default {
    name : 'RegistrationByInvite',
    head : {
      title : {
        inner      : 'Register',
        separator  : '|',
        complement : 'Healthy Hiphop',
      },
    },
    components : {
      NavBar,
      ContactButton,
      SocialMedia,
      Loading,
      RainbowLoader,
      SchoolWidget,
    },
    mixins : [
      ProfanityMixin,
    ],
    data() {
      return {
        isLoading    : false,
        inviterId    : null,
        userType     : null,
        initial      : null,
        hasSchool    : false,
        registration : {
          nickname   : null,
          firstname  : null,
          lastname   : null,
          email      : null,
          password   : null,
          gradeLevel : null,
          schoolId   : null,
        },
        address : null,
        options : [ {
          value    : null,
          text     : 'Grade Level',
          disabled : true,
        }, {
          value : '0',
          text  : 'Kindergarten',
        }, {
          value : '1',
          text  : '1st Grade',
        }, {
          value : '2',
          text  : '2nd Grade',
        }, {
          value : '3',
          text  : '3rd Grade',
        }, {
          value : '4',
          text  : '4th Grade',
        }, {
          value : '5',
          text  : '5th Grade',
        }, {
          value : '6',
          text  : '6th Grade',
        }, {
          value : '7',
          text  : '7th Grade',
        }, {
          value : '8',
          text  : '8th Grade',
        }, {
          value : '9',
          text  : '9th Grade',
        }, {
          value : '10',
          text  : '10th Grade',
        }, {
          value : '11',
          text  : '11th Grade',
        }, {
          value : '12',
          text  : '12th Grade',
        } ],
        response : {
          status : null, // API call request status
        },
        userTypeOptions : [
          {
            value    : null,
            text     : 'User Type',
            disabled : true,
          }, {
            value : '1',
            text  : 'Kid',
          }, {
            value : '2',
            text  : 'Parent',
          }, {
            value : '3',
            text  : 'Educator',
          },
        ],
        safeNickname  : true,
        safeFirstName : true,
        safeLastName  : true,
      };
    },
    computed : {
      showUserTypeField() {
        return !this.$route.params.userType;
      },
    },
    mounted() {
      window.localStorage.removeItem('userType');
      const query = this.$route.params;
      if (query.inviter && query.email) {
        localStorage.inviterId = query.inviter;

        if (query.userType && query.userType != 0)
          this.userType = query.userType;
        else {
          if (query.userType == 0)
            this.$router.push('/404');
        }

        if (query.shortToken)
          localStorage.shortToken = query.shortToken;

        this.registration.email = query.email.toLowerCase();
        this.inviterId = query.inviter;

        // eslint-disable-next-line max-len
        const expression = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        if (!expression.test(String(this.registration.email)))
          this.$router.push('/404');
        

        if (query.school) {
          this.$http.get('/api/school', {
            params : {
              id : query.school,
            },
          }).then((res) => {
            this.initial = res.data.school_id;
            this.hasSchool = true;
          })
        }

      } else
        this.$router.push('/404');
      
    },
    validations() {
      const validations = {
        registration : {
          nickname : {
            required,
            safeNickname() {
              return this.safeNickname;
            },
          },
          firstname : {
            required,
            name,
            minLength : minLength(2),
            safeFirstName() {
              return this.safeFirstName;
            },
          },
          lastname : {
            required,
            name,
            minLength : minLength(2),
            safeLastName() {
              return this.safeLastName;
            },
          },
          password : {
            required,
            minLength : minLength(5),
          },
          email : {
            required,
          },
        },
        userType : {
          required,
        },
      };

      switch (this.userType) {
      case '1':
        validations.registration.gradeLevel = {
          required,
        };
        validations.registration.schoolId = {
          required,
        };
        break;
      case '3':
      case '4':
        validations.registration.gradeLevel = {};
        validations.registration.schoolId = {
          required,
        };
        break;
      default:
        validations.registration.gradeLevel = {};
        validations.registration.schoolId = {};
      }

      return validations;

    },
    watch : {
      'registration.nickname' : function(str) {
        if (str.length)
          this.checkProfanity('safeNickname', str);
        else
          this.safeNickname = true;
        
      },
      'registration.firstname' : function(str) {
        if (str.length)
          this.checkProfanity('safeFirstName', str);
        else
          this.safeFirstName = true;
        
      },
      'registration.lastname' : function(str) {
        if (str.length)
          this.checkProfanity('safeLastName', str);
        else
          this.safeLastName = true;
        
      },
      userType(str) {
        localStorage.userType = str;
      },
    },
    methods : {

      /**
       * Register
       */
      register() {
        this.response.status = 'pending';
        this.isLoading = true;

        if (localStorage.shortToken) {
          instanceRegistration.post('auth/register', {
            'nickname'   : this.registration.nickname,
            'firstname'  : this.registration.firstname,
            'lastname'   : this.registration.lastname,
            'email'      : this.registration.email,
            'password'   : this.registration.password,
            'userType'   : this.userType,
            'schoolId'   : this.registration.schoolId,
            'gradeLevel' : this.registration.gradeLevel,
            'inviterId'  : this.inviterId,
          }).then( async (response) => {
            // successfully registered user
            if (response.status == 201) {
              this.response.status = null;
              await this.$http.post('auth/sendActivationEmail', {
                to : this.registration.email,
              });
              await this.$store.dispatch('auth/login', {
                email    : this.registration.email,
                password : this.registration.password,
              });
              this.isLoading = false;
            }
          }).catch(() => {
            this.response.status = null;
            this.isLoading = false;
          });
        } else {
          this.$http.post('auth/register', {
            'nickname'   : this.registration.nickname,
            'firstname'  : this.registration.firstname,
            'lastname'   : this.registration.lastname,
            'email'      : this.registration.email,
            'password'   : this.registration.password,
            'userType'   : this.userType,
            'schoolId'   : this.registration.schoolId,
            'gradeLevel' : this.registration.gradeLevel,
            'inviterId'  : this.inviterId,
          }).then( async (response) => {
            // successfully registered user
            if (response.status == 201) {
              this.response.status = null;
              await this.$http.post('auth/sendActivationEmail', {
                to : this.registration.email,
              });
              await this.$store.dispatch('auth/login', {
                email    : this.registration.email,
                password : this.registration.password,
              });
              this.isLoading = false;
            }
          }).catch(() => {
            this.response.status = null;
            this.isLoading = false;
          });
        }
      },

      /**
       * Update school data
       *
       * @param data
       */
      updateRegSchool(data) {
        if (data)
          this.registration.schoolId = data;
        else
          this.registration.schoolId = null;
      },
    },
    beforeDestroy() {
      if(this.$route.params.shortToken)
        window.localStorage.removeItem('shortToken');
      window.localStorage.removeItem('inviterId');
      window.localStorage.removeItem('userType');
    },
  };
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/components/registration/registration-by-invite";
</style>
